import React from 'react';
import PropTypes from 'prop-types';
import css from './Article.module.css';

const Article = ({ children }) => {
   return <section className={css.article}>{children}</section>;
};

Article.propTypes = {
   children: PropTypes.node.isRequired,
};

export default Article;
