import React, { useEffect, useState } from 'react';
import Article from '../components/Article';
import Layout from '../components/Layout';
import Spinner from '../components/Spinner';
import SEO from '../components/seo';

const PrivacyPolicy = () => {
   const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         const script = document.createElement('script');

         script.src = 'https://consent.cookiebot.com/03833d84-f8a6-470a-a42c-7ce1cf691a4b/cd.js';
         script.async = true;
         script.id = 'CookieDeclaration';
         script.type = 'text/javascript';

         document.getElementById('cdeclaration').appendChild(script);
      }, 2200);
   }, []);

   useEffect(() => {
      setTimeout(() => {
         setShowPrivacyPolicy(true);
      }, 2000);
   });

   return (
      <Layout>
         <SEO title="Polityka Prywatności" />
         <p style={{ paddingTop: 30 }} />
         <div id="privacy-policy">
            <Article>
               <h1>Polityka prywatności</h1>
               <p>
                  Firma MOSKITOM Tomasz Gruca, właściciel serwisu moskitom.pl dokłada wszelkich starań, aby Państwa
                  prywatność była odpowiednio chroniona. W celu realizacji zgodnego z prawem, przejrzystego i
                  bezpiecznego przetwarzania Państwa danych osobowych przyjmujemy niniejszą Politykę Prywatności, która
                  obowiązuje od dnia 25 maja 2018 r.
               </p>
               <p>
                  Polityka Prywatności odwołuje się do RODO, czyli Rozporządzenia Parlamentu Europejskiego i Rady (UE)
                  2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem
                  danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
                  (ogólne rozporządzenie o ochronie danych). Dokument określa jakie dane i na jakich zasadach
                  przetwarzamy i jak dbamy o ich bezpieczeństwo i Państwa prawa.
               </p>
               <p>
                  Administratorem Państwa danych osobowych jest firma MOSKITOM Tomasz Gruca z siedzibą przy os.
                  Tysiąclecia 31, 31-609 Kraków.
               </p>
               <p>
                  W razie pytań dotyczących przetwarzania Państwa danych osobowych oraz przysługujących Państwu praw,
                  prosimy o kontakt drogą mailową na adres: biuro@moskitom.pl
               </p>

               <h2>Pliki cookies</h2>

               <p style={{ minHeight: 100 }} id="cdeclaration">
                  {!showPrivacyPolicy && <Spinner />}
               </p>
               <p style={{ paddingTop: 30 }} />
            </Article>
         </div>
      </Layout>
   );
};

export default PrivacyPolicy;
